<template>
  <v-card color="grey" :height="height" width="200" tile>
    <!-- <draggable v-model="slots" @start="drag = true" @end="drag = false"> -->

    <template v-for="slot in bookings">
      <v-hover v-slot="{ hover }" :key="slot.index">
        <v-sheet
          block
          :height="slot.height"
          @click.self="onClickSlot(slot)"
          :elevation="hover ? 12 : 0"
          :class="`d-flex justify-center align-center cursorstyle ${
            slot.status
          } ${hover && slot.status != 'not_available' ? 'hoverstyle' : ''}`"
          :color="slot.color"
        >
          <div class="pa-4 text-center white--text">
            <h2>{{ slot.name }}</h2>
          </div>
        </v-sheet>
      </v-hover>
    </template>
    <!-- </draggable> -->
  </v-card>
</template>

<script>
export default {
  props: {
    bookings: { type: Array, default: () => [] },
    increment: { type: Number, default: 60 },
    height: { type: Number, default: 1000 },
    name: { type: String },
    id: { type: Number },
    date: { type: String },
  },

  data() {
    return {};
  },
  methods: {
    onClickSlot(data) {
      this.$emit("open-booking-form", data);
    },
    getParticipants(data) {
      this.$emit("open-participans-model", data);
    },
  },
};
</script>

<style>
</style>