<template>
  <div>
    <v-form>
      <v-dialog v-model="showeventForm" width="70%" scrollable persistent>
        <v-form ref="form" v-model="valid">
          <v-card>
            <v-card-title>
              {{ "Add Booking" }}
              <v-spacer></v-spacer>
              <div
                md="3"
                v-if="promotions.length > 0"
                style="margin-bottom: -20px"
              >
                <v-autocomplete
                  :items="[
                    { name: 'None', promotion_code: null },
                    ...promotions,
                  ]"
                  item-text="name"
                  height="50"
                  item-value="promotion_code"
                  v-model="bookingForm.promotion_code"
                  background-color="rgb(206, 168, 0)"
                  outlined
                  @change="verifyBenefit('promotion')"
                  label="Promotions"
                >
                </v-autocomplete>
              </div>
            </v-card-title>
            <div class="d-flex justify-space-around headline pa-4">
              <div class="pitch">Event: {{ event_name }}</div>
              <div class="pitch">Service: {{ service }}</div>
              <div class="pitch">
                Price: {{ bookingForm.price | toCurrency }}
                <span
                  v-if="bookingForm.discount != null"
                  class="text-decoration-line-through"
                >
                  {{ bookingForm.discount.actual_price | toCurrency }}</span
                >
              </div>
            </div>

            <v-card-text class="form_bg">
              <v-card
                color="#edf9ff"
                style="border: 1px #ccc solid"
                class="pa-4"
                outlined
              >
                <div class="titles">Workshop Details</div>
                <v-divider></v-divider>

                <div class="titles d-flex justify-space-between">
                  <div>Customer Details</div>
                </div>
                <v-divider></v-divider>
                <v-row no-gutters>
                  <v-col md="4">
                    <v-radio-group
                      v-model="bookingForm.customer_type"
                      class="d-flex"
                      row
                      @change="customerTypeChange"
                      mandatory
                      :readonly="order_id > 0"
                    >
                      <v-radio
                        label="Normal"
                        color="cyan"
                        value="normal"
                      ></v-radio>
                      <v-radio
                        label="Corporate"
                        color="cyan"
                        value="corporate"
                      ></v-radio>
                      <v-radio
                        label="Member"
                        color="cyan"
                        value="member"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col md="3" v-if="bookingForm.customer_type == 'member'">
                    <v-member-search
                      v-model="bookingForm.member"
                      @clear="clearBenefit"
                      :selected="bookingForm.card_number"
                      @updateCustomer="setMemberData"
                      class="mt-4"
                    >
                    </v-member-search>
                  </v-col>
                  <v-col md="3" v-if="bookingForm.customer_type == 'corporate'">
                    <v-autocomplete
                      class="mt-4"
                      label="Company Name"
                      :items="companies"
                      v-model="bookingForm.company_id"
                      item-text="name"
                      item-value="id"
                      outlined
                      background-color="#fff"
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col md="2" v-if="bookingForm.customer_type == 'corporate'">
                    <v-autocomplete
                      :disabled="bookingForm.company_id == null"
                      :items="getCompanySales()"
                      label="Sale Order ID"
                      item-text="sale_seq_no"
                      item-value="id"
                      class="mt-4 ml-2"
                      v-model="bookingForm.company_sale_id"
                      outlined
                      background-color="#fff"
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-col sm="4" md="4">
                    <div>
                      <v-mobile-search
                        label="Mobile No*"
                        outlined
                        :selected="bookingForm.mobile"
                        @updateCustomer="setCustomerData"
                        readonly
                        v-model="bookingForm.search"
                      ></v-mobile-search>
                    </div>
                  </v-col>
                  <v-col sm="4" md="4">
                    <div>
                      <v-name-field
                        v-model="bookingForm.name"
                        outlined
                        background-color="#fff"
                        label="Name*"
                        required
                        @split="setCustomerName($event)"
                        :rules="nameRule"
                      ></v-name-field>
                    </div>
                  </v-col>
                  <v-col sm="4" md="4">
                    <div>
                      <v-text-field
                        outlined
                        background-color="#fff"
                        v-model="bookingForm.email"
                        :label="`Email${field.email.is_required ? '*' : ''}`"
                        :placeholder="`Email${
                          field.email.is_required ? '*' : ''
                        }`"
                        :rules="emailRule"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col sm="4" md="4" v-if="field.gender.is_visible">
                    <v-select
                      :items="['Male', 'Female']"
                      :placeholder="`Gender${
                        field.gender.is_required ? '*' : ''
                      }`"
                      :label="`Gender${field.gender.is_required ? '*' : ''}`"
                      outlined
                      :menu-props="{ bottom: true, offsetY: true }"
                      v-model="bookingForm.gender"
                      :rules="genderRule"
                      background-color="#fff"
                    ></v-select>
                  </v-col>
                  <v-col sm="4" md="4" v-if="field.dob.is_visible">
                    <date-of-birth
                      :placeholder="`Date of Birth${
                        field.dob.is_required ? '*' : ''
                      }`"
                      :label="`Date of Birth${
                        field.dob.is_required ? '*' : ''
                      }`"
                      :rules="dobRule()"
                      v-model="bookingForm.dob"
                    >
                    </date-of-birth>
                  </v-col>
                  <v-col sm="4" md="4" v-if="field.nationality.is_visible">
                    <v-autocomplete
                      :items="countries"
                      :hint="`Nationality${
                        field.nationality.is_required ? '*' : ''
                      }`"
                      :label="`Nationality${
                        field.nationality.is_required ? '*' : ''
                      }`"
                      :rules="nationalityRule"
                      item-value="id"
                      item-text="name"
                      outlined
                      v-model="bookingForm.country_id"
                      background-color="#fff"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="4" md="4" v-if="field.idProof.is_visible">
                    <v-select
                      :hint="`ID Type${field.idProof.is_required ? '*' : ''}`"
                      :label="`ID Type${field.idProof.is_required ? '*' : ''}`"
                      :rules="idTypeRule"
                      outlined
                      :menu-props="{ bottom: true, offsetY: true }"
                      item-value="id"
                      item-text="name"
                      :items="idProofTypes"
                      v-model="bookingForm.id_proof_type_id"
                      background-color="#fff"
                    ></v-select>
                  </v-col>
                  <v-col sm="4" md="4" v-if="field.idProof.is_visible">
                    <v-row no-gutters>
                      <v-col md="7">
                        <v-text-field
                          :hint="`ID Number${
                            field.idProof.is_required ? '*' : ''
                          }`"
                          :label="`ID Number${
                            field.idProof.is_required ? '*' : ''
                          }`"
                          :rules="idTypeRule"
                          class="text_field1"
                          outlined
                          v-model="bookingForm.id_proof_number"
                          background-color="#fff"
                        ></v-text-field>
                      </v-col>
                      <v-col md="5">
                        <v-file-input
                          v-model="bookingForm.id_proof"
                          class="text_field2"
                          :placeholder="`Select image${
                            field.image.is_required ? '*' : ''
                          }`"
                          :label="`ID Proof${
                            field.image.is_required ? '*' : ''
                          }`"
                          :rules="idProofRule"
                          prepend-icon=""
                          prepend-inner-icon="mdi-card-account-details"
                          background-color="#fff"
                          outlined
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index == 0"
                              color="cyan accent-4"
                              dark
                              label
                              small
                            >
                              <span style="width: 40px" class="text-truncate">{{
                                text
                              }}</span>
                            </v-chip>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>
                    <div style="margin-top: -110px"></div>
                  </v-col>
                  <v-col sm="4" md="4" v-if="field.image.is_visible">
                    <v-row no-gutters>
                      <v-col md="8">
                        <v-file-input
                          v-model="bookingForm.image"
                          class="text_field1"
                          prepend-icon=""
                          :label="`Customer Image${
                            field.image.is_required ? '*' : ''
                          }`"
                          :placeholder="`Image${
                            field.image.is_required ? '*' : ''
                          }`"
                          :rules="imageRule"
                          prepend-inner-icon="mdi-image"
                          background-color="#fff"
                          outlined
                          show-size
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index == 0"
                              color="cyan accent-4"
                              dark
                              label
                              small
                            >
                              <span
                                style="width: 120px"
                                class="text-truncate"
                                >{{ text }}</span
                              >
                            </v-chip>
                          </template>
                        </v-file-input>
                      </v-col>
                      <v-col md="4">
                        <v-btn
                          large
                          block
                          style="background-color: #fff"
                          outlined
                          height="56"
                          color="blue-grey"
                          class="white--text text_field2"
                          @click="webcamDialog = true"
                        >
                          <v-icon dark>mdi-camera</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="bookingForm.status_id == 5"
                @click="$emit('pay', bookingForm.order_id)"
                class="ma-2 white--text teal-color"
                text
              >
                Pay
              </v-btn>
              <v-btn
                v-if="bookingForm.status_id == 5"
                @click="confirmCancel"
                class="ma-2 white--text red-color"
                text
                >Cancel Booking
              </v-btn>
              <v-btn
                v-if="bookingForm.repeat_dates && id > 0"
                @click="confirmSinglePayment"
                class="ma-2 white--text teal-color"
                text
                >Pay for {{ date | dateformat }}
              </v-btn>
              <v-btn
                v-if="
                  bookingForm.repeat_dates &&
                  id > 0 &&
                  bookingForm.status_id == 5
                "
                @click="cancelSingleDate"
                class="ma-2 white--text red-color"
                text
                >Cancel {{ date | dateformat }} Booking
              </v-btn>
              <v-btn
                class="ma-2 white--text blue-color"
                @click="confirmBooking"
                text
              >
                Confirm Booking
              </v-btn>
              <v-btn @click="close()" class="ma-2 white--text teal-color" text
                >Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-form>
    <capture-image
      :open="webcamDialog"
      @close="webcamDialog = false"
      @confirm="confirmImageCapture"
    />
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </div>
</template>
<script>
import VMemberSearch from "@/components/Customer/VMemberSearch";
import CaptureImage from "@/components/Image/CaptureImage";
import moment from "moment";
import bookingFields from "@/mixins/bookingFieldValidation";

export default {
  props: {
    showeventForm: { type: Boolean },
    start_time: { type: String },
    end_time: { type: String },
    date: { type: String },
    facility_id: { type: Number },
    order_id: { type: Number },
    id: { type: Number, default: 0 },
    event_name: { type: String },
    venue_service_id: { type: Number },
    service: { type: String },
    event_id: { type: Number },
    location: { type: String },
    event_type_id: { type: Number },
    start_date: { type: String },
    end_date: { type: String },
    image_path: { type: String },
    participants: { type: Number, default: 0 },
    sales: { type: Number, default: 0 },
  },
  components: {
    CaptureImage,
    VMemberSearch,
  },
  mixins: [bookingFields],
  data() {
    return {
      event: {},
      bookingForm: { price: 0 },
      selectedTicket: {},
      productCategoryId: null,
      webcamDialog: false,
      endTimes: [],
      categories: [],
      companies: [],
      valid: false,
      repeatData: {
        available: [],
        excluded: [],
      },
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
    };
  },
  watch: {
    showeventForm(val) {
      if (val === true) {
        this.bookingForm = { price: 0 };
        this.selectedTicket = {};
        this.getWorkshopDetails();
        this.$store.dispatch("loadPromotions", {
          date: this.date,
          venue_service_id: this.venue_service_id,
          product_type: "Academy",
        });
      }
    },
  },
  mounted() {
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getIdProofTypes.status == false) {
      this.$store.dispatch("loadIDProofTypes");
    }
  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.data;
    },
    promotions() {
      return this.$store.getters.getPromotions.data;
    },
    idProofTypes() {
      return this.$store.getters.getIdProofTypes.data;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },

    getOrderDetails() {
      this.showLoader("Loading");
      this.$http
        .get(`venues/orders/${this.order_id}?event`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();

            this.setCustomerData(response.data.data.customer);
            this.$set(
              this.bookingForm,
              "name",
              response.data.data.customer.first_name +
                response.data.data.customer.last_name
            );

            this.bookingForm.price = 0;
            response.data.data.items.forEach((ticket) => {
              this.bookingForm.price += ticket.product.price;
              this.bookingForm.products.push({
                order_item_id: ticket.id,
                price: ticket.product.price,
                name: ticket.product.name,
                quantity: ticket.quantity,
                product_id: ticket.product_id,
                event_ticket_id: this.event.tickets.find(
                  (x) => x.product_id == ticket.product_id
                ).event_ticket_id,
              });
            });

            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    getWorkshopDetails() {
      this.showLoader("Loading");
      this.$http
        .get(`venues/workshops/${this.id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.editMode = true;
            let eventData = response.data.data;
            this.event = eventData;

            if (this.order_id) {
              this.getOrderDetails();
            }
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    setCustomerData(data) {
      if (
        this.bookingForm.customer_id &&
        !data.customer_id &&
        this.bookingForm.name != data.name
      ) {
        this.$set(this.bookingForm, "mobile", null);
        this.bookingForm.search = null;
        this.bookingForm.nameSearch = null;
        this.$set(this.bookingForm, "email", null);
        this.$set(this.bookingForm, "gender", null);
        this.$set(this.bookingForm, "name", null);
        this.$set(this.bookingForm, "customer_id", null);
        this.$set(this.bookingForm, "first_name", null);
        this.$set(this.bookingForm, "image_path", null);
        this.$set(this.bookingForm, "dob", null);
        this.$set(this.bookingForm, "country_id", null);
        this.$set(this.bookingForm, "last_name", null);
        this.$forceUpdate();
      }

      if (data.mobile) this.$set(this.bookingForm, "mobile", data.mobile);
      if (data.email) this.$set(this.bookingForm, "email", data.email);
      if (data.country_id) {
        this.$set(this.bookingForm, "country_id", data.country_id);
      } else {
        this.$set(this.bookingForm, "country_id", null);
      }
      if (data.gender) {
        this.$set(this.bookingForm, "gender", data.gender);
      } else {
        this.$set(this.bookingForm, "gender", null);
      }
      if (data.dob) {
        this.$set(this.bookingForm, "dob", data.dob);
      } else {
        this.$set(this.bookingForm, "dob", null);
      }
      if (data.name) this.$set(this.bookingForm, "name", data.name);
      if (data.last_name)
        this.$set(this.bookingForm, "last_name", data.last_name);
      if (data.first_name)
        this.$set(this.bookingForm, "first_name", data.first_name);
      if (data.customer_id)
        this.$set(this.bookingForm, "customer_id", data.customer_id);
    },
    setMemberData(data) {
      this.$set(this.bookingForm, "card_number", data.card_number);
      this.setCustomerData(data);
      this.verifyBenefit("membership");
    },
    setCustomerName(data) {
      this.$set(this.bookingForm, "last_name", data.last_name);
      this.$set(this.bookingForm, "first_name", data.first_name);
    },
    confirmImageCapture(image) {
      image.name = this.bookingForm.name
        ? this.bookingForm.name + "_" + moment().format("YYYYMMDDHHSS")
        : "user_image_" + moment().format("YYYYMMDDHHSS");
      this.bookingForm.image = image;
      this.webcamDialog = false;
    },

    selectTicket() {
      if (
        this.selectedTicket.quantity == 0 ||
        this.selectedTicket.quantity == this.selectedTicket.participants ||
        this.selectedTicket.quantity < this.selectedTicket.participants
      ) {
        this.showError("Tickets not available");
        this.selectedTicket = {};
        return;
      }

      this.selectedTicket.ticketQnt = 1;
      this.$forceUpdate();
    },

    quantityCheck() {
      if (
        this.selectedTicket.ticketQnt >
        this.selectedTicket.quantity - this.selectedTicket.participants
      ) {
        this.showError("Quantity larger than available tickets");
      }
    },

    carosal(direction) {
      if (direction == "left")
        this.event.lineups.unshift(this.event.lineups.pop());
      else this.event.lineups.push(this.event.lineups.shift());
    },

    verifyBenefit(type) {
      if (this.bookingForm.products.length == 0) {
        this.showError("Please add atleast one product");
        return;
      }
      let data = {
        products: [],
      };
      if (type == "promotion") {
        data.promotion_code = this.bookingForm.promotion_code;
        if (data.promotion_code == null) {
          this.clearBenefit();
          return;
        }
      } else {
        data.card_number = this.bookingForm.card_number;
      }
      if (this.bookingForm.customer_id) {
        data.customer_id = this.bookingForm.customer_id;
      }
      if (this.bookingForm.discount) {
        data.products = [];
        this.bookingForm.products.forEach((product) => {
          let pdata = product;
          if (product.discount) {
            pdata.price = product.discount.actual_price;
            delete pdata.discount;
          }
          data.products.push(pdata);
        });
      } else {
        data.products = this.bookingForm.products;
      }
      let url = "venues/benefits/verify";
      this.$http
        .post(url, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.bookingForm.discount = data.discount;
            this.bookingForm.price = data.price;
            this.bookingForm.products = data.products;
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    clearBenefit() {
      if (
        this.bookingForm.discount &&
        Object.keys(this.bookingForm.discount).length
      ) {
        this.bookingForm.price = this.bookingForm.discount.actual_price;
        delete this.bookingForm.discount;
        this.bookingForm.products.forEach((product, index) => {
          this.bookingForm.products[index].price =
            product.discount.actual_price;
          delete this.bookingForm.products[index].discount;
        });
      }
      this.$forceUpdate();
    },
    customerTypeChange() {
      if (this.bookingForm.customer_type == "corporate") {
        this.getActiveCompanySales();
      }
    },
    getActiveCompanySales() {
      this.$http
        .get(`venues/companies/active/sales`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.companies = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getCompanySales() {
      return this.bookingForm.company_id != null && this.companies.length
        ? this.companies.find((item) => item.id == this.bookingForm.company_id)
            .company_sale
        : [];
    },

    confirmSinglePayment() {
      let booking = this.bookingForm.repeat_dates.find(
        (item) => item.date == this.date
      );
      this.confirmModel = {
        id: booking.id,
        title: `Pay only for ${moment(booking.date).format("Do MMM YYYY")}?`,
        description: `Do you want to seperately invoice for ${moment(
          booking.date
        ).format(
          "Do MMM YYYY"
        )} from other repeat dates? By clicking <b>Yes</b> you can confirm creating new invoice`,
        type: "singleInvoice",
      };
    },
    cancelSingleDate() {
      let booking = this.bookingForm.repeat_dates.find(
        (item) => item.date == this.date
      );
      this.confirmModel = {
        id: booking.id,
        title: `Cancel ${moment(booking.date).format("Do MMM YYYY")} booking?`,
        description: `Do you want to cancel booking for ${moment(
          booking.date
        ).format(
          "Do MMM YYYY"
        )} from repeated dates? By clicking <b>Yes</b> you can confirm cancelation`,
        type: "singleCancel",
      };
    },
    confirmActions() {},
    confirmBooking() {},

    timeFormat(time) {
      return moment(time, "HH:mm:ss").format("hh:mm a");
    },

    confirmCancel() {
      this.confirmModel = {
        id: this.order_id,
        title: `Do you want cancel this booking?`,
        description: `This will cancel current booking. By clicking <b>Yes</b> you can confirm cancel operation`,
        type: "cancel",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
$card-outlined-border-width: 3px;
</style>