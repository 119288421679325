<template>
  <v-container fluid>
    <v-row
      justify="center"
      v-if="
        checkReadPermission($modules.facility.schedule.slug) ||
        checkReadPermission($modules.events.schedule.slug) ||
        checkReadPermission($modules.workshops.schedule.slug)
      "
      no-gutters
      class="mt-3 pb-4 scheduleSelection"
    >
      <v-hover v-slot:default="{ hover }">
        <v-btn
          icon
          :elevation="hover ? 2 : 0"
          :color="hover ? 'teal' : 'grey'"
          @click="getWorkshopSchedule"
        >
          <v-icon>mdi-refresh-circle</v-icon>
        </v-btn>
      </v-hover>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-col
        md="2"
        justify="center"
        v-if="checkReadPermission($modules.facility.schedule.slug)"
      >
        <router-link :to="`/schedule`">
          <v-btn block light tile>Facility</v-btn>
        </router-link>
      </v-col>
      <v-col
        md="2"
        justify="center"
        v-if="checkReadPermission($modules.events.schedule.slug)"
      >
        <router-link :to="`/event-schedule`">
          <v-btn block light tile>Events</v-btn>
        </router-link>
      </v-col>
      <v-col
        md="2"
        justify="center"
        v-if="checkReadPermission($modules.workshops.schedule.slug)"
      >
        <router-link :to="`/workshop-schedule`">
          <v-btn block dark color="#062b48" tile>Workshop</v-btn>
        </router-link>
      </v-col>
      <v-spacer></v-spacer>
      <v-col md="2" class="text-lg-right mr-2">
        <v-select
          :items="venueServices"
          v-model="venueService"
          item-value="venue_service_id"
          item-text="name"
          outlined
          :menu-props="{ bottom: true, offsetY: true }"
          return-object
          background-color="#fff"
          dense
          @change="initializeScheduleForVenueService()"
        ></v-select>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="4">
        <router-link :to="`/event-schedule`">
          <v-btn color="#062b48" dark tile>Day</v-btn>
        </router-link>
        <router-link :to="`/workshopCalender`">
          <v-btn light tile>Month</v-btn>
        </router-link>
      </v-col>
      <v-spacer></v-spacer>
      <v-col sm="4" class="pa-4">
        <v-row no-gutters>
          <v-col sm="1" class="text-lg-center mr-1">
            <v-btn fab dark x-small color="teal" @click="prevDate">
              <v-icon dark>mdi-menu-left</v-icon>
            </v-btn>
          </v-col>
          <v-col sm="8" class="text-lg-center">
            <date-field
              v-model="date"
              :button="true"
              @change="getWorkshopSchedule"
            >
            </date-field>
          </v-col>
          <v-col sm="1" class="text-lg-center ml-1">
            <v-btn fab dark x-small color="teal" @click="nextDate">
              <v-icon dark>mdi-menu-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col sm="4" class="text-lg-right">
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-col
            v-if="
              checkReadPermission($modules.facility.schedule.slug) &&
              !(
                checkReadPermission($modules.events.schedule.slug) ||
                checkReadPermission($modules.workshops.schedule.slug)
              )
            "
            sm="5"
            class="text-lg-right mr-2"
          >
            <v-select
              :items="venueServices"
              v-model="venueService"
              item-value="venue_service_id"
              item-text="name"
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
              background-color="#fff"
              dense
              return-object
              @change="changeCategory()"
            ></v-select>
          </v-col>

          <v-col
            :sm="
              !(
                checkReadPermission($modules.events.schedule.slug) ||
                checkReadPermission($modules.workshops.schedule.slug)
              )
                ? 5
                : 6
            "
            class="text-lg-right mr-2"
          >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="d-flex">
      <header-cell name="Time"></header-cell>
      <template v-for="facility in workshops">
        <header-cell
          :key="`h_${facility.name}`"
          :increment="increment"
          :height="height"
          :name="facility.name"
          :formation="facility.game_formations"
          :tooltip="!facility.id ? facility.location : null"
        ></header-cell>
      </template>
    </div>

    <div
      v-resize="onResize"
      class="d-flex overflow-y-auto grey--text text--lighten-2 caption"
      :style="`min-height: ${scheduleHeight}px; max-height: ${scheduleHeight}px`"
    >
      <time-column :height="height"></time-column>
      <template v-for="(facility, index) in workshops">
        <workshop-column
          :key="`h_${facility.name}`"
          :height="height"
          @open-booking-form="openBooking"
          :name="facility.name"
          :id="facility.id"
          :bookings="getSlotData(index)"
        >
        </workshop-column>
      </template>
    </div>

    <workshop-booking-form
      v-bind="workshopForm"
      @close="workshopForm.showeventForm = false"
      @booked="completeOrder"
      @cancel="getWorkshopSchedule"
      @pay="openOrderCloseBooking"
      :increment="increment"
    ></workshop-booking-form>

    <order-details
      :id="orderId"
      @close="orderId = null"
      @paymentDone="getWorkshopSchedule"
    ></order-details>
  </v-container>
</template>

<script>
import TimeColumn from "@/components/Schedule/TimeColumn";
import WorkshopColumn from "@/components/Schedule/Workshop/WorkshopColumn";
import HeaderCell from "@/components/Schedule/HeaderCell";
import OrderDetails from "@/components/Order/OrderDetails";
import WorkshopBookingForm from "./WorkshopBookingForm.vue";

import moment from "moment";
export default {
  components: {
    WorkshopColumn,
    TimeColumn,
    HeaderCell,
    OrderDetails,
    WorkshopBookingForm,
  },
  data() {
    return {
      drag: false,
      workshops: [],
      workshopForm: {},
      date: moment().format("YYYY-MM-DD"),
      venueService: {},
      gameFormationFilter: [],
      increment: 60,
      height: 1200,
      perCapacity: 0,
      orderId: null,
      participant: {},
      minBookingTime: 60,
      scheduleHeight: 500,
      scheduleData: [],
      eventSchedule: [],
      participans: {},
      currentEvent: {},
    };
  },
  mounted() {
    this.onResize();
    if (this.$store.getters.getVenueServices.status == false) {
      this.showLoader("Loading");
      this.$store.dispatch("loadVenueServices").then(() => {
        this.$nextTick(() => {
          if (this.$store.getters.getSportsService.length) {
            this.venueService = this.$store.getters.getSportsService[0];
            this.initializeScheduleForVenueService();
          }
          this.hideLoader();
        });
      });
    } else {
      if (this.$store.getters.getSportsService.length) {
        this.venueService = this.$store.getters.getSportsService[0];
        this.initializeScheduleForVenueService();
      }
    }
    if (
      !this.$store.getters.getConfigurationStatus(
        this.venueService.venue_service_id
      )
    ) {
      this.$store.dispatch(
        "loadConfigurationsByVenueServiceId",
        this.venueService.venue_service_id
      );
    }
  },
  computed: {
    venueServices() {
      return this.$store.getters.getSportsService;
    },
    venueServiceConfiguration() {
      return this.$store.getters.getConfigurationByVenueServiceId(
        this.venueService.venue_service_id
      );
    },
  },
  methods: {
    initializeScheduleForVenueService() {
      this.getWorkshopSchedule();
    },
    onResize() {
      this.scheduleHeight = window.innerHeight - 350;
    },
    gameFormations() {
      return this.$store.getters.getGameFormationsByVenueServiceId(
        this.venueService.venue_service_id
      );
    },
    openBooking(data) {
      this.openWorkshopForm(data.event);
    },

    openWorkshopForm(data) {
      this.workshopForm = {
        showeventForm: true,
        start_time: moment(data.start_time, "hh:mm a").format("HH:mm:ss"),
        end_time: moment(data.end_time, "hh:mm a").format("HH:mm:ss"),
        start_date: data.start_date,
        end_date: data.end_date,
        event_name: data.name,
        facility_id: data.facility_id,
        event_id: data.id,
        date: this.date,
        venue_service_id: this.venueService.venue_service_id,
        service: this.venueService.name,
        id: data.id != null ? data.id : 0,
        event_type_id: data.event_type_id,
        image_path: data.image_path,
        location: data.location,
        participants: data.participants,
        sales: data.sales,
      };
    },

    getWorkshopSchedule() {
      this.showLoader("Loading");
      this.$http
        .get(`venues/workshops/schedules?date=${this.date}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.workshops = response.data.data;
            this.workshops.forEach((element) => {
              this.scheduleData = [];
              if (element.programs.length > 0) {
                var programs = element.programs;
                programs.forEach((program) => {
                  program.schedules.forEach((ev) => {
                    let scheduleData = [];
                    var start_time = moment(ev.start_time, "HH:mm:ss");
                    var end_time = moment(ev.end_time, "HH:mm:ss");
                    let TimeDifferent = end_time.diff(start_time, "hours");

                    for (let index = 0; index < 1440; index += 60) {
                      var element = {};
                      if (
                        start_time.format("hh:mm a") ==
                        moment()
                          .startOf("day")
                          .add(index, "minutes")
                          .format("hh:mm a")
                      ) {
                        element = {
                          index: index,
                          start_time: moment()
                            .startOf("day")
                            .add(index, "minutes")
                            .format("hh:mm a"),
                          end_time: moment()
                            .startOf("day")
                            .add(index + 60, "minutes")
                            .format("hh:mm a"),
                          height: TimeDifferent * 50 + (TimeDifferent - 1),
                          fixed: true,
                          status: "work_shop",
                          event: ev,
                          end_index: index + TimeDifferent * 60,
                          color: "rgb(0, 89, 118)",
                          name: program.name,
                        };
                        scheduleData.push(element);
                      }
                    }
                    this.scheduleData.push(...scheduleData);
                  });
                });
                var sheData = [];
                for (let index = 0; index < 1440; index += 60) {
                  var checkindex = true;
                  this.scheduleData.forEach((element) => {
                    if (element.index == index) {
                      checkindex = false;
                      index = element.end_index - 60;
                    }
                  });
                  if (checkindex) {
                    sheData.push({
                      index: index,
                      start_time: moment()
                        .startOf("day")
                        .add(index, "minutes")
                        .format("hh:mm a"),
                      end_time: moment()
                        .startOf("day")
                        .add(index + 60, "minutes")
                        .format("hh:mm a"),
                      height: 51,
                      fixed: true,
                      color: "rgb(223, 247, 255)",
                    });
                  }
                }
                this.scheduleData.push(...sheData);
                this.scheduleData.sort((a, b) => a.index - b.index);
              }

              this.eventSchedule.push(this.scheduleData);
            });
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    nextDate() {
      this.date = moment(this.date).add(1, "days").format("YYYY-MM-DD");
      this.getWorkshopSchedule();
    },
    prevDate() {
      this.date = moment(this.date).subtract(1, "days").format("YYYY-MM-DD");
      this.getWorkshopSchedule();
    },
    getSlotData(index) {
      return this.eventSchedule[index];
    },

    getOrderDetails(orderId) {
      this.participans.show = false;
      this.orderId = orderId;
    },

    openOrderCloseBooking(orderId) {
      this.workshopForm.showeventForm = false;
      this.orderId = orderId;
      this.currentEvent = {};
      this.getWorkshopSchedule();
    },
    completeOrder(orderId) {
      // this.getWorkshopSchedule();
      this.openOrderCloseBooking(orderId);
    },
  },
};
</script>

<style>
</style>